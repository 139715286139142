<template>
  <div class="customer-pool">
    <div class="search-box">
      <el-form :inline="true" :model="searchForm" class="search-form">
        <el-form-item>
          <el-input v-model="searchForm.name" placeholder="客户名称" clearable @keyup.enter.native="handleSearch" />
        </el-form-item>
        <el-form-item>
          <el-input v-model="searchForm.code" placeholder="客户编号" clearable @keyup.enter.native="handleSearch" />
        </el-form-item>
        <el-form-item>
          <el-input v-model="searchForm.sales" placeholder="归属销售" clearable @keyup.enter.native="handleSearch" />
        </el-form-item>
        <el-form-item>
          <el-input v-model="searchForm.department" placeholder="部门" clearable @keyup.enter.native="handleSearch" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch">查询</el-button>
          <el-button @click="resetSearch">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-table v-loading="loading" :data="customerList" border style="width: 100%">
      <el-table-column label="客户信息" width="300">
        <template slot-scope="scope">
          <div>{{ scope.row.customer_name }}</div>
          <div style="font-size: 12px; color: #666; margin-top: 5px;">
            客户编号：{{ scope.row.customer_code }}
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="sales_person" label="归属销售" width="120" /> -->
      <el-table-column prop="filing_person" label="报备人" width="150" />
      <el-table-column prop="filing_region" label="部门" width="120" />
      <el-table-column prop="contact_address" label="联系地址" show-overflow-tooltip />
      <el-table-column prop="credit_code" label="统一社会信用代码" width="180" show-overflow-tooltip />
      <el-table-column prop="modify_date" label="更新时间" width="130">
        <template slot-scope="scope">
          {{ formatDate(scope.row.modify_date) }}
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page" :page-sizes="[10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next" :total="total" />
    </div>
  </div>
</template>

<script>
import { getCustomers } from '@/api'

export default {
  name: 'CustomerPool',
  data () {
    return {
      loading: false,
      searchForm: {
        name: '',
        code: '',
        sales: '',
        department: ''
      },
      customerList: [],
      page: 1,
      pageSize: 10,
      total: 0
    }
  },
  created () {
    this.fetchCustomers()
  },
  methods: {
    formatDate (dateStr) {
      if (!dateStr) return ''
      const date = new Date(dateStr)
      return date.toLocaleString('zh-CN', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      }).replace(/\//g, '-')
    },
    async fetchCustomers () {
      this.loading = true
      try {
        const res = await getCustomers({
          customer_name: this.searchForm.name,
          customer_code: this.searchForm.code,
          sales_person: this.searchForm.sales,
          department: this.searchForm.department,
          page: this.page,
          page_size: this.pageSize
        })
        this.customerList = res.data.list
        this.total = res.data.pagination.total
      } catch (error) {
        console.error('获取客户列表失败:', error)
        this.$message.error('获取客户列表失败')
      } finally {
        this.loading = false
      }
    },
    handleSearch () {
      this.page = 1
      this.fetchCustomers()
    },
    resetSearch () {
      this.searchForm = {
        name: '',
        code: '',
        sales: '',
        department: ''
      }
      this.handleSearch()
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.fetchCustomers()
    },
    handleCurrentChange (val) {
      this.page = val
      this.fetchCustomers()
    }
  }
}
</script>

<style scoped>
.customer-pool {
  padding: 20px;
}

.search-form {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.pagination-container {
  margin-top: 20px;
  text-align: right;
}
</style>